
.loading {
  justify-content: center;
  align-items: center;   /* first 3 items are for centered #taskContainerContent */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.2); /* Black w/ opacity */
  height: 100vh;
  width: 100vw;
  position: fixed; /* Stay in place */
  top: 0;
  left: 0;
  z-index: 10000;
}

.loading_content {
  margin: 50vh auto;
  width: 50px;
  height: 50px;
  top: 50%;
}