.image-wrapper {
  position: relative;
  width: 100%; /* Set the desired width */
  max-width: 370px; /* Example max-width */
  aspect-ratio: 370 / 300; /* This keeps the `<a>` tag at a fixed ratio */
  overflow: hidden; /* Ensures image stays contained */
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-wrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: fill; /* Ensures the image keeps its aspect ratio */
}
